@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
    @variants responsive {
      /* Hide scrollbar for Chrome, Safari and Opera */
      .no-scrollbar::-webkit-scrollbar {
          display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .no-scrollbar {
          -ms-overflow-style: none;  /* IE and Edge */
          scrollbar-width: none;  /* Firefox */
      }
    }
  }

@font-face {
  font-family: "AeonikBold";
  src: url("../public/Fonts/Aeonik-Bold.ttf");
}


@font-face {
  font-family: "AeonikMedium";
  src: url("../public/Fonts/Aeonik-Medium.ttf");
}

@font-face {
  font-family: "AeonikRegular";
  src: url("../public/Fonts/Aeonik-Regular.ttf");
}

@font-face {
  font-family: "Grifter";
  src: url("../public/Fonts/grifterbold.ttf");
}
